import { render, staticRenderFns } from "./PackagesSettingsDash.vue?vue&type=template&id=03515796&scoped=true&"
import script from "./PackagesSettingsDash.vue?vue&type=script&lang=js&"
export * from "./PackagesSettingsDash.vue?vue&type=script&lang=js&"
import style0 from "./PackagesSettingsDash.vue?vue&type=style&index=0&id=03515796&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03515796",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAutocomplete,VBtn,VCol,VForm,VIcon,VNavigationDrawer,VRow,VToolbar,VToolbarTitle})
